
.html{
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, Arial, sans-serif;
}
.nav{


    /* font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, Arial, sans-serif; */
    width: 100%;
    height: 100px;
    display: flex;
    /* display: flexbox; */
    /* color: #d33b3b; */
    /* background-color: #d33b3b; */
    padding: 10px;
    align-items: center;
    background-image:url('yellow.jpg') ;
    
  
    /* border-radius:2cqmin; */
   background-blend-mode:darken;
    background-position-y: 57%;
}

.nav img{
    float: left;
 
    padding-left: 50px;
    padding-top: 40px;
    width: 200px;;
    height: 60px;
}
.nav ul{
    margin-top: 60px;
    /* display: block; */
    align-items: center;
}
.nav ul li{
    /* padding-top: 30px; */
    display: inline-block;
    /* padding: 10px; */
    padding-left: 90px;
    /* align-items: center; */
    
}
.nav ul li a{
    display: inline-flex;
    font-size: 25px;
    /* font-size: x-large; */
    text-decoration:  none;
    /* color: red; */
    /* align-items: center; */
    color:black ;
   /* font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
   font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, Arial, sans-serif;

}

a:hover{
    background-color: yellow;
    
}
a:focus{
    background-color: yellow;
}

@media screen and (max-width:768px) 
{
    .nav{
        /* width: 97%; */
        height: 100px;
        display: inline-block;
        color: #d33b3b;
        /* background-color: #d33b3b; */
        padding: 10px;
        padding-left: 14px;
        background-image:url('yellow.jpg') ;
    
  
    /* border-radius:2cqmin; */
   background-blend-mode:darken;
    background-position-y: 60%;
        
    }
   .nav img{
    /* float: left; */
    display: inline;
    /* padding: 0%; */
    padding-left: 30px;
    padding-top: 40px;
    width: 100px;;
    height: 40px;
   }
    .nav ul li{
        display: inline-block;
        padding: 10px;
        padding-left: 10px;
        /* background-color: #d33b3b; */
    }
    .nav ul li a{
        /* display: inline-flex; */
        font-size: 20px;
        float: none;
        display: block;
        align-items: center;
        text-decoration:  none;
        color:black ;
   /* font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif */
   font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, Arial, sans-serif;
        
    }

    
}