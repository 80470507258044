.main-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: none; /* Set border to none */
    margin: auto;
    padding-left: 10px;
    text-align: center;
    flex-wrap: wrap; /* Wrap to a new line on smaller screens */
}
.mainPic{
    padding: 0%;
    margin:auto;
    /* background-color: black; */
    width: 100%;
    
    height: auto;
    /* border-radius: 20px; */
}

.mainPic img{
    object-fit: cover;
    padding: 0%;
    width: 100%;
    height: 520px;
    /* border-radius: 25px; */
    /* display:flexbox; */
    
}

.sub-div {
    /* float: left; */
    /* display: inline-block; */
   width: 100%;
    box-sizing: border-box;
    /* border-style: dashed; */
    border-radius: 30px;
    /* background-color: beige; */
    /* margin-left: 5px; */
    /* align-items: center; */
    padding-left: 65px;
    margin: auto 10px;
    /* padding-top: 30px; */
}
.degradation{
    width: 100%;
    box-sizing: border-box;
    /* border-style: dashed; */
    border-radius: 30px;
    /* background-color: beige; */
    /* margin-left: 10px; */
    align-items: center;
    padding-left: 10px;
    margin: auto ;
    
}

.section{
    /* display: inline; */
    float: left;
    display: grid;
    /* grid-row-start: auto; */
    width: 22%;
    margin: 10px 60px;
    /* padding: 10px; */
    border-radius: 10px;
    /* background-color:blue ; */
    /* align-items: center; */
    
    
}

.deg-section{
    float: left;
    display: grid;
    /* grid-row-start: auto; */
    width: 12%;
    margin: 10px 90px;
    /* padding: 10px; */
    border-radius: 10px;
    align-items: center;
    text-align: center;
}

.img-section{
    /* float: left; */
    /* display: inline-block; */
    width: 100%;
    height: 100%;
    margin: 10px auto;
    /* margin-bottom: 20px; */
    /* align-items: center; */
    /* height: 250px; */
    /* /* float: left; 
    width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between; */
    /* background-color: rgb(14, 14, 7); */
}


.text-box {
    width: 100%;
    max-height: 200px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 1vh;
}





.img-section img {
    align-items: center;
    /* float: left; */
     width: 250px; /*Set a fixed width for the image */
    height: 250px; /*Set a fixed height for the image */
     
    /* Ensures the image doesn't stretch */
    /* object-fit: cover; */
    /* display: flex; */
    margin: auto;
    border-radius: 10px;
    transition: transform .5s ease;  
}

.img-section img:hover{
    transform: scale(1.5);

}

@media screen and (max-width: 768px) {

    .degradation {
        width: 90%;
        padding-left: 0;
        margin: auto;
    }

    .section {
        width: 100%;
        margin: 10px auto;
    }

    .deg-section {
        width: 100%;
        margin: 10px auto;
    }

    .img-section img {
        width: 100%; /* Set the image to take up 100% of the container width */
        height: auto;
    }

    .text-box {
        width: 100%;
        padding: 10px;
    }
}


@media screen and (max-width:768px){
       
        .main-content{
            width:auto;
            display: block;
            float: none;
        }

        .mainPic{
            margin-top: 20px;
            /* background-color: black; */
            width: 100%;
            height: 350px;
            /* border-radius: 20px; */
        }
        
        .mainPic img{
            width: 100%;
            height: 200px;
            /* border-radius: 25px; */
        }

        .sub-div{
            /* width: 100%; */
            margin: 10px auto;
            padding: 0%;
        }

        .section{
            width: 100%;
            display: block;
            float:none;
            margin: 10px;
        }
        .text-box {
            display: block;
            width: 100%;
            padding: 10px;
            box-sizing: border-box; 
            /* background-color: #c0a5a5; */
            border-radius: 1vh;
            /* overflow: hidden; */
            word-wrap: break-word; /* Allow long words to wrap */
        }

        .sub-div img {
        
            width: 180px; /*Set a fixed width for the image */
           height: 180px; /*Set a fixed height for the image */
           object-fit: cover; /* Ensures the image doesn't stretch */
           display: block;
           margin: 0 auto;
           align-items: center;
           /* background-color: rebeccapurple; */
       }

       .img-section{
        align-items:center ;
        /* background-color: black; */
        width: 100%;
       }

       .section-1 p{
        display: block;
        width: 100%;
        padding: 10px;
        box-sizing: border-box; 
        /* background-color: #c0a5a5; */
        border-radius: 1vh;
        /* overflow: hidden; */
        word-wrap: break-word; /* Allow long words to wrap */
    }
    .section-1{
        display: block;
        width: 100%;
        /* background-color: black; */
    }

    
    }

    .yellow-background {
        width: 100%;
        /* background-color: aliceblue; */
        margin: 30px auto;
       /* align-items: stretch; */
    }

    
    .icon-background {
        margin-top: 30px;
    padding: 1% 1%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    position: relative;
    /* width: 90%; */
    min-height: 40vh;
    /* background-color: white; */
    opacity: 0.9;
    border-radius: 5vh;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom;

    @media screen and (max-width: 768px) {
        min-height: 20vh;
    }
}

.panels {
    /* width: 100%; */
    flex: 1;
    display: block;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
    border-radius: 5vh;
    margin: 5px;
}

.panel-content {
    /* width: 100%; */
    border-radius: 5vh;
    display: block;
    align-items: center;
    justify-content: center;
    max-height: 40vh;
    overflow: hidden;
    /* background-color: aliceblue; */
}

.panel-content img {
    width: 300px;
    height: 300px;
    object-fit: cover; 
    border-radius: 10px; 
}



.panel-content h1 {
    margin: 0;
}


.parallax-text {
    /* position: relative;
    z-index: 1; */
    /* color: white; */
    /* margin-top: 30px ; */
    /* margin-left: 230px; */
    margin: 20PX auto;
    /* width: 100%; */
    text-align: center;
    font-size: 1.5em; 
    font-weight: bold;
    /* padding: 10px;  */
    border-radius: 10px;
    /* background:yellow; */
    font-size:xx-large;
    /* display: block; */
    
}

.parallax-text span{
    /* margin: 30PX auto; */
    width: auto;
    text-align: center;
    font-size: 1.5em; 
    font-weight: bold;
    /* padding: 10px;  */
    border-radius: 10px;
    /* background:yellow; */
    font-size:xx-large;
}



.section-1 p{
    
    width: 60%;
    float: left;
    padding-left: 30px;
    padding-top: 40px;
    text-align: center;
    word-wrap: break-word;
    font-size: larger;
    /* background-color: black; */

}

.section-1{
    width: 100%;
    margin-bottom: 60px;
}

.mainPic p{
     font-size: large;
}

.img-section1 img{
    width: 250px;
    height: 250px;
    object-fit: cover;
}


/* bootstrap */
.custom-img {
    width: 50%;
    height: 250px;
    object-fit: contain;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
}

.card-title{
    margin: auto;
    width: 40%;
    background-color: rgb(255, 255, 255);
    font-size: larger;
    font-weight: bold;
}

.card-text {
    font-size: 17px; /* Adjust the font size as needed */
    width: 60%;
    max-height: 80px; /* Adjust the max height as needed */
    margin: auto;
    max-width: 100%; /* Adjust as needed */
    padding: 3% 10% 13%;
}

header{
    margin: auto;
    background-color: aliceblue;
}

@media screen and (max-width: 768px) {
    .custom-img{
        max-width: 40%;
    }

    .col-md-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .card-text{
        padding-bottom: 25%;
    }
}

