.footer-basic {
    padding:50px, 50px;
    background-color:rgb(255, 255, 255);
    color:#000000;
  }
  
  .footer-basic ul {
    padding:0;
    list-style:none;
    text-align:center;
    font-size:18px;
    line-height:1.6;
    margin-bottom:0;
  }
  
  .footer-basic li {
    padding:0 25px;
    display: inline-block;
  }
  
  .footer-basic ul a {
   /* display: inline-block; */
    color:inherit;
    text-decoration:none;
    opacity:0.8;
  }
  
  .footer-basic ul a:hover {
    opacity:1;
  }
  
  
  .footer-basic .copyright {
    margin-top:15px;
    text-align:center;
    font-size:13px;
    color:#aaa;
    margin-bottom:0;
  }

  .socials a + a {
    margin-left: 35px;
  }

   .socials img{
    max-width:50px;
    max-height: 50px;

  }