/* FormStyles.css */

body {
  margin: 0;
  padding: 0;
  /* background-image: url('./customersupport.jpg'); */
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.form-container {
  margin-top: 20px;
  display: inline-block;
    max-width: 400px;
    /* margin: auto; */
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 100px;
  }
  
  .form-container h1 {
    text-align: center;
    color: #333;
  }
  
  .form-label {
    display: block;
    margin-bottom: 8px;
  }
  
  .form-input {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .form-textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .form-button {
    background-color: #fff309;
    color: rgb(0, 0, 0);
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    border-radius: 1vh;
  }
  
  .form-button:hover {
    background-color: #45a049;
  }
  
  .contact-us{
    /* background-color: rgba(255,255,0,0.4); */
    max-width: 400px;
    /* margin: auto; */
    padding: 20px;
    /* background-color: yellow; */
    border-radius: 8px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    margin-left: 300px;
    float: left;
    text-align: center;
    height: 200px;
    margin-top: 10%;
    /* color: white; */
    /* background-color: #333; */
     
  }
  h1{
       background-color: rgba(255,255,0,0.6);
  }
  .list{
     display: inline-block;
  text-align: left;
  }

  @media screen and (max-width:768px) {
    .contact-us{
      display: block;
      height: 300px;
      /* max-width: 400px; */
      margin: auto;
      /* padding: 20px; */
      /* background-color: yellow; */
      /* border-radius: 8px; */
      /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
       margin-left: 5px; 
      /* float: left; */
      text-align: center;
      width: 90%;
      /* margin-top: 20px; */
      /* background-color: #333; */
      
    }
    
  }
    
  